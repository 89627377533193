"use client";
import { resolveLink } from "$src/lib/sanity";
import { cx } from "@tracksuit/utils";
import { X } from "lucide-react";
import Link from "next/link";
import {
  useCallback,
  useRef,
  type ComponentProps,
  useState,
  useEffect
} from "react";
import styles from "./announcement-bar.module.css";
import { usePathname } from "next/navigation";

export type AnnouncementBarProps = {
  /** Region */
  region: string;
  /** Announcement copy */
  copy: string;
  /** Announcement Link */
  link: any;
} & ComponentProps<"div">;

export type AnnouncementState = {
  announcement: string;
  closed: boolean;
};

export type AnnouncementBarState = {
  state: AnnouncementState;
};

/**
 * @component
 * Global announcements bar
 */
export function AnnouncementBar({
  region,
  copy,
  link,
  className
}: AnnouncementBarProps) {
  const storage = useRef<AnnouncementBarState>();
  const bannerLink = useRef(resolveLink(region, link));
  const [visible, setVisible] = useState<boolean>();
  const pathname = usePathname();

  const dismiss = useCallback((event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (!storage.current) return;

    // State
    storage.current.state.closed = true;
    // Save
    window?.localStorage?.setItem(
      "announcement",
      JSON.stringify(storage.current)
    );
    setVisible(false);
  }, []);

  // Mount
  useEffect(() => {
    const state = window?.localStorage?.getItem("announcement");

    if (state) {
      storage.current = JSON.parse(state);
    } else {
      storage.current = {
        state: {
          announcement: copy,
          closed: false
        }
      };

      // Save
      window?.localStorage?.setItem(
        "announcement",
        JSON.stringify(storage.current)
      );
    }

    storage.current = state
      ? JSON.parse(state)
      : {
          state: {
            announcement: copy,
            closed: false
          }
        };
  }, []);

  useEffect(() => {
    if (storage.current) {
      // New
      if (storage.current.state.announcement !== copy) {
        // State
        storage.current.state.closed = false;
        storage.current.state.announcement = copy;
        // Save
        window?.localStorage?.setItem(
          "announcement",
          JSON.stringify(storage.current)
        );
      }

      if (pathname === bannerLink.current) {
        // State
        dismiss();
      } else {
        setVisible(!storage.current.state.closed);
      }
    }
  }, [copy, pathname, dismiss]);

  return (
    visible && (
      <Link href={bannerLink.current} className={cx(styles.bar, className)}>
        <span className={styles.copy}>{copy}</span>
        <X
          data-testid="close"
          className={styles.close}
          onClick={(e: React.MouseEvent) => {
            dismiss(e);
          }}
        />
      </Link>
    )
  );
}
